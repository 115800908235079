import Amplify, { AuthModeStrategyType, DataStore, syncExpression } from 'aws-amplify';
import { startOfDay, subMinutes } from 'date-fns';
import config from 'src/aws-exports';
import {
    Area,
    CleaningTask,
    CleaningTaskRecord,
    Cooling,
    Delivery,
    DeliveryPicture,
    Equipment,
    Freezing,
    FryerCheck,
    FryerEquipment,
    Preparation,
    Product,
    Reheating,
    Restaurant,
    Sensor,
    Supplier,
    SurfaceAnalysis,
    TemperatureReading,
    TemperatureRecord,
    TraceabilityLabel,
    User
} from 'src/models';

const now = new Date();
const startOfDayTimestamp = subMinutes(startOfDay(now), 1).getTime().toString();

type SyncTimestampKey =
    | 'cleaningTaskRecord'
    | 'cooling'
    | 'freezing'
    | 'delivery'
    | 'fryerCheck'
    | 'temperatureRecord'
    | 'traceabilityLabel'
    | 'surfaceAnalysis'
    | 'reheating'
    | 'temperatureReading';

const syncTimestamps = {
    cleaningTaskRecord: startOfDayTimestamp,
    cooling: startOfDayTimestamp,
    freezing: startOfDayTimestamp,
    delivery: startOfDayTimestamp,
    fryerCheck: startOfDayTimestamp,
    temperatureRecord: startOfDayTimestamp,
    traceabilityLabel: startOfDayTimestamp,
    surfaceAnalysis: startOfDayTimestamp,
    reheating: startOfDayTimestamp,
    temperatureReading: startOfDayTimestamp
};

const restartDataStore = async () => {
    await DataStore.stop();
    await DataStore.start();
};

export const setSyncTimestamp = async (
    owner: string,
    groups: string[] = [],
    isOrg: boolean,
    key: SyncTimestampKey,
    value: string
) => {
    syncTimestamps[key] = value;
    configureDataStore(`${owner}::${owner}`, groups, isOrg);
    await restartDataStore();
};

export const configureAuth = (): void => {
    Amplify.configure({
        ...config,
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        DataStore: {
            authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
        }
    });
};

export const configureDataStore = async (
    owner: string,
    groups: string[] = [],
    isOrg: boolean
): Promise<void> => {
    if (isOrg && groups?.length < 2) return;
    DataStore.configure({
        errorHandler: (error: any) => {
            throw new Error(JSON.stringify(error));
        },
        maxRecordsToSync: 10000,
        syncExpressions: [
            syncExpression(TemperatureRecord, () => {
                return (temperatureRecord) =>
                    isOrg
                        ? temperatureRecord
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.temperatureRecord)
                        : temperatureRecord
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.temperatureRecord);
            }),
            syncExpression(TraceabilityLabel, () => {
                return (traceabilityLabel) =>
                    isOrg
                        ? traceabilityLabel
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.traceabilityLabel)
                        : traceabilityLabel
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.traceabilityLabel);
            }),
            syncExpression(Delivery, () => {
                return (delivery) =>
                    isOrg
                        ? delivery
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.delivery)
                        : delivery
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.delivery);
            }),
            syncExpression(CleaningTaskRecord, () => {
                return (cleaningTaskRecord) =>
                    isOrg
                        ? cleaningTaskRecord
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.cleaningTaskRecord)
                        : cleaningTaskRecord
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.cleaningTaskRecord);
            }),
            syncExpression(Cooling, () => {
                return (cooling) =>
                    isOrg
                        ? cooling
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.cooling)
                        : cooling
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.cooling);
            }),
            syncExpression(Freezing, () => {
                return (freezing) =>
                    isOrg
                        ? freezing
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.freezing)
                        : freezing
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.freezing);
            }),
            syncExpression(FryerCheck, () => {
                return (fryerCheck) =>
                    isOrg
                        ? fryerCheck
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.fryerCheck)
                        : fryerCheck
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.fryerCheck);
            }),
            syncExpression(SurfaceAnalysis, () => {
                return (surfaceAnalysis) =>
                    isOrg
                        ? surfaceAnalysis
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.surfaceAnalysis)
                        : surfaceAnalysis
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.surfaceAnalysis);
            }),
            syncExpression(Reheating, () => {
                return (reheating) =>
                    isOrg
                        ? reheating
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.reheating)
                        : reheating
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.reheating);
            }),
            syncExpression(TemperatureReading, () => {
                return (temperatureReading) =>
                    isOrg
                        ? temperatureReading
                              .or((t) =>
                                  groups.reduce((acc, group) => acc.groupName('eq', group), t)
                              )
                              .timestamp('gt', syncTimestamps.temperatureReading)
                        : temperatureReading
                              .owner('eq', `${owner}::${owner}`)
                              .timestamp('gt', syncTimestamps.temperatureReading);
            }),
            syncExpression(Restaurant, () => {
                return (restaurant) =>
                    isOrg
                        ? restaurant.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : restaurant.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(Area, () => {
                return (area) =>
                    isOrg
                        ? area.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : area.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(User, () => {
                return (user) =>
                    isOrg
                        ? user.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : user.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(Sensor, () => {
                return (sensor) =>
                    isOrg
                        ? sensor.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : sensor.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(Equipment, () => {
                return (equipment) =>
                    isOrg
                        ? equipment.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : equipment.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(Supplier, () => {
                return (supplier) =>
                    isOrg
                        ? supplier.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : supplier.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(Product, () => {
                return (product) =>
                    isOrg
                        ? product.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : product.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(Preparation, () => {
                return (preparation) =>
                    isOrg
                        ? preparation.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : preparation.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(DeliveryPicture, () => {
                return (deliveryPicture) =>
                    isOrg
                        ? deliveryPicture.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : deliveryPicture.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(CleaningTask, () => {
                return (cleaningTask) =>
                    isOrg
                        ? cleaningTask.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : cleaningTask.owner('eq', `${owner}::${owner}`);
            }),
            syncExpression(FryerEquipment, () => {
                return (fryerEquipment) =>
                    isOrg
                        ? fryerEquipment.or((t) =>
                              groups.reduce((acc, group) => acc.groupName('eq', group), t)
                          )
                        : fryerEquipment.owner('eq', `${owner}::${owner}`);
            })
        ],
        syncPageSize: 300
    });
    return;
};
