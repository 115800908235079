import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import rtl from 'stylis-plugin-rtl';

import { useLanguage } from '../hooks/useLanguage';

const options = {
    rtl: { key: 'css-ar', stylisPlugins: [rtl] },
    ltr: { key: 'css-en' }
};

export function RTLProvider({ children }: { children: React.ReactNode }) {
    const { language } = useLanguage();
    const dir = language === 'ar' ? 'rtl' : 'ltr';
    const cache = createCache(options[dir]);
    return <CacheProvider value={cache}>{children}</CacheProvider>;
}
