import { useEffect, useState } from 'react';
import { detectLanguage } from 'src/utils/helpers/i18n';

export const useLanguage = () => {
    const [language, setLanguage] = useState('en');

    useEffect(() => {
        const init = async () => {
            const detectedLanguage = await detectLanguage();
            setLanguage(detectedLanguage);
            document.documentElement.setAttribute('dir', detectedLanguage === 'ar' ? 'rtl' : 'ltr');
        };

        init();
    }, []);

    return { language };
};
