import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

const defaultLanguage = 'en';
const supportedLanguages = [
    'en',
    'en-US',
    'en-EG',
    'en-AU',
    'en-GB',
    'en-CA',
    'en-NZ',
    'en-IE',
    'en-ZA',
    'en-JM',
    'en-BZ',
    'en-TT',
    'fr',
    'fr-FR',
    'fr-BE',
    'fr-CA',
    'fr-CH',
    'fr-LU',
    'de',
    'de-DE',
    'de-AT',
    'de-CH',
    'de-LU',
    'de-LI',
    'es',
    'es-ES',
    'es-MX',
    'es-AR',
    'es-CO',
    'es-CL',
    'es-PE',
    'es-VE',
    'pt',
    'pt-BR',
    'pt-PT',
    'it',
    'it-IT',
    'it-CH',
    'ar',
    'ar-SA',
    'ar-EG',
    'ar-DZ',
    'ar-TN',
    'ar-MA',
    'tr',
    'tr-TR',
    'pl',
    'pl-PL',
    'ro',
    'ro-RO',
    'hi',
    'hi-IN',
    'zh',
    'zh-CN',
    'zh-TW',
    'zh-HK'
];
export const detectLanguage = async () => {
    if (typeof window === 'undefined') {
        return defaultLanguage;
    }

    const storedLanguage = localStorage.getItem('app_language');
    if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
        return storedLanguage.split('-')[0];
    }

    if (Capacitor.isNativePlatform()) {
        const language = await Device.getLanguageCode();
        if (supportedLanguages.includes(language.value)) {
            return language.value.split('-')[0];
        } else {
            return defaultLanguage;
        }
    } else {
        const language = window.navigator.language;
        if (supportedLanguages.includes(language)) {
            return language.split('-')[0];
        } else {
            return defaultLanguage;
        }
    }
};
